<template>
	<div
		class="flex-column full-height bg-gray-light position-relative"
	>
		<div
			v-if="!user.virtual_uid"
			class="full-height justify-center items-center bg-white"
		>
			<div class="bg-white pa-20-10 text-center size-px-16 font-weight-bold width-100 radius-20">설정된 가상계좌가 없습니다. <br/> <br/> 관리자에게 문의하세요</div>
		</div>
		<div
			v-else
			class="pa-20 bg-gray-light full-height"
		>
			<div class="font-weight-bold size-px-16"><button  @click="toMypage">{{ user.account_name }}<span class="font-weight-500">님 <v-icon>mdi-chevron-right</v-icon></span></button></div>

			<div class=" ">
				<div class="wrap-card mt-20 ">
					<div
						v-if="item_wallet.uid"
						class="main-amount pa-20"
					>
						<div class="text-left word-break">{{ item_wallet.virtual_account }}</div>

						<div class="mt-30 ptb-10 text-left size-px-24">{{ item_wallet.balance | makeComma }}원</div>

						<div class="mt-10 justify-space-between">
							<button
								@click="toWithdrawal"
								class="btn btn-primary radius-20 mr-10 flex-3"
							>이체</button>
							<button
								@click="toList"
								class="btn btn-primary-outline radius-20 flex-1"
							>내역</button>
						</div>
					</div>
					<div
						v-else
						class="main-amount pa-20"
					>

						<div class=" size-px-20">등록된 지갑이 없습니다.</div>
						<div class="mt-30 font-weight-bold">
							<button
								@click="onCreateWallet"
								class="btn btn-primary"
							>지갑 생성</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_on_create_wallet"
			@cancel="is_on_create_wallet = false"
			@click="postCreateWallet"
		>
			<template
				v-slot:title
			>지갑 생성</template>
			<template
				v-slot:main-txt
			>출금 지갑을 생성합니다</template>
		</PopupConfirm>
	</div>
</template>

<script>

import PopupConfirm from "../Layout/PopupConfirm";

export default{
	name: 'WalletList'
	, components: {PopupConfirm}
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, bottom: true
				, top: true
			}
			, item: {
				user_balance: 0
				, settlement_balance: 0
				, deposit_balance: 0
			}
			, item_merchant: {
				balanceTotAmt: 0
			}
			, is_on_merchant_history: false
			, is_on_merchant_withdrawal: false
			, item_withdrawal: {
				amount: ''
				, bank: ''
				, account: ''
				, holder: ''
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, depositor: ''
			}
			, item_search_history: {
				page: 1
				, list_cnt: 10
				, sDate: ''
				, eDate: ''
			}
			, items_history: []
			, is_on_create_wallet: false
			, is_holder: false
			, item_holder: {}
			, item_wallet: {}
		}
	}
	,computed: {
		is_to_merchant_withdrawal: function(){
			let t = true
			if(this.item_withdrawal.bank && this.item_withdrawal.account && this.item_withdrawal.holder && this.item_withdrawal.amount && this.item_withdrawal.depositor){
				if(this.item_merchant.balanceTotAmt >= Number(this.item_withdrawal.amount) + Number(this.user.virtual_fee)){
					t = false
				}
			}

			return t
		}
		, list_history: function(){
			return this.items_history.filter( (item) => {

				if(item.send_type == 1){
					item.type_name = '출금'
					item.type_color = 'color-red'
				}else{
					item.type_name = '입금'
					item.type_color = 'color-blue'
				}
				if(item.status == 1){
					item.status_name = '완료'
					item.status_color = 'color-green'
				}else{
					item.status_name = '-'
				}

				this.user.bank_list.filter( (bank) => {
					if(bank.bankId == item.bank_code){
						item.bank_name = bank.bankName
					}
				})
				return item
			})
		}
		, is_sms_confirm: function(){
			let t = true
			if(this.user.sms_auth_uid){
				t = false
			}
			return t
		}
		, txt_amount: function(){
			let t = 0
			if(this.item_withdrawal.amount){
				t = this.$common.geKoreanNumber((this.item_withdrawal.amount + '').replaceAll(',', ''))
			}
			return t
		}
		, is_payment: function(){
			let t = false
			if(this.user.is_payment){
				t = true
			}
			return t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: 'member/getMainInfo'
					, data: this.search
				})

				if (result.success) {
					this.item_merchant = result.data.merchant_balance
					this.item_wallet = result.data.wallet_info
					this.item.deposit_balance = result.data.deposit_balance
					this.item.settlement_balance = result.data.settlement_balance
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMerchantHistory: function(){
			this.getMerchantHistory()
		}
		, onMerchantWithdrawal: async function(){
			await this.getData()
			this.is_on_merchant_withdrawal = true
		}
		, getMerchantHistory: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: this.$api_url.api_path.get_merchant_history
					, data: this.item_search_history
				})

				if (result.success) {
					this.items_history = result.data
					this.is_on_merchant_history = true
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postMerchantWithdrawal: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_merchant_withdrawal
					, data: this.item_withdrawal
				})

				if (result.success) {
					await this.getData()
					this.is_on_merchant_withdrawal = false
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				this.$set(this.item_withdrawal, 'pin', call)
				this.postPinCheck(call)
			})
		}
		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					await this.postMerchantWithdrawal()
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSmsConfirm (){
			this.$bus.$emit('onSmsConfirm')
			this.$bus.$off('smsCallback')
			this.$bus.$on('smsCallback', (item) => {
				this.$set(this.item_withdrawal, 'sms_confirm', item)
				this.postMerchantWithdrawal()
			})
		}
		, onCreateWallet: function(){
			this.is_on_create_wallet = true
		}
		, postCreateWallet: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'member/postCreateWallet'
					, data: {
						shop_uid: this.user.shop_uid
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.is_on_create_wallet = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postHoder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'member/postHolder'
					, data: this.item_withdrawal
				})
				if(result.success){
					this.is_holder = true
					this.item_holder = result.data
				}else{
					throw result.message
				}
			}catch(e){
				this.is_holder = false
				this.item_holder = {}
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toMypage: function(){
			this.$bus.$emit('to', { name: 'MyPage'})
		}
		, toList: function(){
			this.$bus.$emit('to', { name: 'WalletList', params: { shop_uid: this.user.shop_info.uid}})
		}
		, toWithdrawal: function(){
			this.$bus.$emit('to', { name: 'WalletWithdrawal'})
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
		if(this.user.virtual_uid){
			this.getData()
		}
	}
}

</script>
